import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { styled } from '@mui/system';
import * as actions from 'ROOT/apps/manager/src/services/ui/actions';

import useConfig from 'Lib/hooks/api/useConfig';
import { screenSize } from 'Lib/utilities/screenSize';
import userDevice from 'Lib/utilities/userDevice';

import Impersonate from './Impersonate';

const { os } = userDevice();
const iOSDevice = os === 'iOS';

export default function PaperContainer({ children, isImpersonated, large, open }) {
    const dispatch = useDispatch();
    const {
        portal: { isManager }
    } = useConfig();
    const pinned = !!useSelector(state => state.ui.pinnedContentTypeID);
    const { mobile, tablet } = screenSize();
    const isMobile = mobile || tablet;
    const heightAdjustment = isManager ? '110px' : '48px';
    const [dynamicHeight, setDynamicHeight] = useState(`calc(100vh - ${heightAdjustment})`);

    const updateHeight = () => setDynamicHeight(`${window.innerHeight - 64}px`);

    useEffect(() => {
        if (iOSDevice) {
            updateHeight();
            window.addEventListener('resize', updateHeight);

            return () => window.removeEventListener('resize', updateHeight);
        }
    }, []);

    /*
        There are many pages that do not have certain PinnedContainers. For instance, the Settings page does not have a PinnedContainer for any sort of Filters (like Properties) but
        does have a PinnedContainer for Notifications. This useEffect waits a very short time (150ms) to give the element a very small time to render and then check it's children. If
        at all the children are empty, it will clear the pinned drawer since it is not valid for that page.
    */
    useEffect(() => {
        // * Wait 150ms for PinnedContainer to render (this time is arbitrary but very short to allow for it to render on location change).
        // * Check if the PinnedContainer has children; if not, dispatch an action to close the PinnedContainer
        const waitForPinnedContainer = setTimeout(() => {
            const pinnedContainer = document.getElementById('pinned-container');
            if (!pinnedContainer?.hasChildNodes()) {
                dispatch(actions.togglePinnedDrawer());
            }
        }, 150);

        return () => clearTimeout(waitForPinnedContainer);
    }, []);

    return (
        <>
            <StyledContainer open={open} isMobile={isMobile} pinned={pinned} dynamicHeight={dynamicHeight}>
                <Impersonate open={isImpersonated} />
                {children}
            </StyledContainer>
            {/* Do not delete ID; used as a portal div for PinnedContainer */}
            <div id="pinned-container" />
        </>
    );
}

// eslint-disable-next-line no-unused-vars
const StyledContainer = styled(({ isMobile, open, pinned, dynamicHeight, ...other }) => <Paper {...other} />, {
    shouldForwardProp: prop => !['isMobile', 'pinned', 'dynamicHeight'].includes(prop)
})(({ theme, isMobile, open, pinned, dynamicHeight }) => ({
    boxShadow: '0px 1px 2px #B6C7D6',
    borderRadius: '20px',
    overflow: 'auto',
    width: pinned && `calc(100vw - ${open ? 680 : 512}px)`,
    height: `${isMobile && !iOSDevice ? '-webkit-fill-available' : dynamicHeight}`,
    marginTop: !isMobile ? theme.spacing(6) : theme.spacing(16),
    marginRight: !isMobile ? theme.spacing(pinned ? 4 : 6) : 0,
    marginLeft: !isMobile ? theme.spacing(77) : 0,
    ...(isMobile && {
        borderRadius: 0,
        width: '100%',
        height: 'auto' /**/,
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - ${theme.spacing(16)})`,
            maxWidth: '100vw'
        }
    })
}));
