// @ts-check
import { useMemo, useReducer } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import useAgent from './hooks/api/useAgent';
import { initialState, uploadReducer, UploadsContext } from './hooks/api/useUpload';

/**
 * Creates a new QueryClient instance.
 *
 * @param {Function} get - The GET request function.
 * @param {Function} post - The POST request function.
 * @returns {QueryClient} The created QueryClient instance.
 */
const createQueryClient = (get, post) => {
    return new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
                queryFn: async ({ queryKey, signal }) => {
                    // queryKey is an array of strings and objects. The first string is the URL, the first object is the params.
                    const params = queryKey.filter(key => typeof key === 'object')[0];
                    const url = queryKey.filter(key => key && typeof key !== 'object').join('/');

                    const { data, headers } = await get(url, params, signal).catch(error => {
                        throw error;
                    });

                    data.headers = headers;

                    return data;
                }
            },
            mutations: {
                mutationFn: defaultMutationFn('POST', post)
            }
        }
    });
};

/**
 * @param {{children: any}} param0
 * @returns
 */
export function QueryClientWrapper({ children }) {
    const { get, post } = useAgent();

    // If global state updates, memoizing protects the cache from being recreated.
    const queryClient = useMemo(() => createQueryClient(get, post), []);

    return (
        <QueryClientProvider client={queryClient}>
            <UploadsContextWrapper>
                {children} <ReactQueryDevtools initialIsOpen={false} />
            </UploadsContextWrapper>
        </QueryClientProvider>
    );
}

function UploadsContextWrapper({ children }) {
    const [uploads, dispatch] = useReducer(uploadReducer, initialState);

    // if there are pending uploads, alert the user before they leave the page
    window.onbeforeunload = function () {
        if (uploads.some(upload => upload.status === 'pending')) {
            return 'You have pending uploads. Are you sure you want to leave?';
        }
    };

    return <UploadsContext.Provider value={{ uploads, dispatch }}>{children}</UploadsContext.Provider>;
}

export default QueryClientWrapper;

const defaultMutationFn = (opt = 'POST', post) => {
    return async function mutationFn({ mutationKey, body, config }) {
        // mutationKey is an array of strings and objects. The first string is the URL, the first object is the params, the last object is the body.
        const params = mutationKey.filter(key => typeof key === 'object')[0];
        const url = mutationKey.filter(key => typeof key === 'string').join('/');
        const formData = new FormData();

        // map every key value pair to the form data. If body is an array,
        // it will be converted to FormData and submitted as a multipart form
        if (body && Array.isArray(body)) {
            body.forEach(item => {
                Object.entries(item).forEach(([key, value]) => {
                    formData.append(key, value);
                });
            });
        }

        const { data } = await post(url, Array.isArray(body) ? formData : body, params, config, opt).catch(error => {
            throw error;
        });

        return data;
    };
};

export const useDeleteMutation = () => {
    const { post } = useAgent();

    return () => defaultMutationFn('DELETE', post);
};