import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ModuleRegistry } from 'ag-grid-community';
import { AllEnterpriseModule, LicenseManager } from 'ag-grid-enterprise';

import theme from 'Lib/theme';
import { initializeSentry } from 'Lib/utilities/sentryUtils';

import App from './containers/App';
import store from './store';

import './tailwind.css';

import 'large-small-dynamic-viewport-units-polyfill';

initializeSentry();

LicenseManager.setLicenseKey(process.env.AG_GRID_KEY);

// Register all enterprise features
ModuleRegistry.registerModules([AllEnterpriseModule]);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<CssBaseline enableColorScheme />
			<App />
		</ThemeProvider>
	</Provider>
);
